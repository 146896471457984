// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.checkbox-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr)); /* Adjust the minmax value as needed */
    gap: 10px; /* Optional: Adjust spacing between checkboxes */
    margin-bottom: 20px;
  }
  
  .checkbox-container label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    width: 30px;  /* Adjust width as needed */
    height: 20px;  /* Adjust height as needed */
    border: 2px solid #005792;
    border-radius: 4px;
    background-color: #f0f0f0;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .checkbox-container input[type="checkbox"] {
    appearance: none;
    cursor: pointer;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label {
    background-color: #007acc;
    color: white;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label::after {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/EarlyAdvFeeds.css"],"names":[],"mappings":";;;AAGA;IACI,aAAa;IACb,2DAA2D,EAAE,sCAAsC;IACnG,SAAS,EAAE,gDAAgD;IAC3D,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,WAAW,GAAG,2BAA2B;IACzC,YAAY,GAAG,4BAA4B;IAC3C,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;EACd","sourcesContent":["\n\n\n.checkbox-container {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr)); /* Adjust the minmax value as needed */\n    gap: 10px; /* Optional: Adjust spacing between checkboxes */\n    margin-bottom: 20px;\n  }\n  \n  .checkbox-container label {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 14px;\n    cursor: pointer;\n    width: 30px;  /* Adjust width as needed */\n    height: 20px;  /* Adjust height as needed */\n    border: 2px solid #005792;\n    border-radius: 4px;\n    background-color: #f0f0f0;\n    position: relative;\n    user-select: none;\n  }\n  \n  .checkbox-container input[type=\"checkbox\"] {\n    appearance: none;\n    cursor: pointer;\n  }\n  \n  .checkbox-container input[type=\"checkbox\"]:checked + label {\n    background-color: #007acc;\n    color: white;\n  }\n  \n  .checkbox-container input[type=\"checkbox\"]:checked + label::after {\n    font-size: 14px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

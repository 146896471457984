// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Styling for input fields in the ParameterForm */
form input[type="number"] {
    max-width: 200px; /* Set a maximum width appropriate for 6-digit numbers */
    padding: 8px 12px;
    margin: 10px 0;
    border: 2px solid #005792; /* Keeping the styling consistent with your theme */
    border-radius: 5px;
  }
  


/* Styling for input fields specifically in the ParameterForm */
.parameter-form input[type="number"] {
    max-width: 200px; /* Set a maximum width appropriate for 6-digit numbers */
    padding: 8px 12px;
    margin: 10px 0;
    border: 2px solid #005792; /* Keeping the styling consistent with your theme */
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/ParameterForm.css"],"names":[],"mappings":";;AAEA,kDAAkD;AAClD;IACI,gBAAgB,EAAE,wDAAwD;IAC1E,iBAAiB;IACjB,cAAc;IACd,yBAAyB,EAAE,mDAAmD;IAC9E,kBAAkB;EACpB;;;;AAIF,+DAA+D;AAC/D;IACI,gBAAgB,EAAE,wDAAwD;IAC1E,iBAAiB;IACjB,cAAc;IACd,yBAAyB,EAAE,mDAAmD;IAC9E,kBAAkB;EACpB","sourcesContent":["\n\n/* Styling for input fields in the ParameterForm */\nform input[type=\"number\"] {\n    max-width: 200px; /* Set a maximum width appropriate for 6-digit numbers */\n    padding: 8px 12px;\n    margin: 10px 0;\n    border: 2px solid #005792; /* Keeping the styling consistent with your theme */\n    border-radius: 5px;\n  }\n  \n\n\n/* Styling for input fields specifically in the ParameterForm */\n.parameter-form input[type=\"number\"] {\n    max-width: 200px; /* Set a maximum width appropriate for 6-digit numbers */\n    padding: 8px 12px;\n    margin: 10px 0;\n    border: 2px solid #005792; /* Keeping the styling consistent with your theme */\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SwitchDisplay.css*/

.switch-display-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #007BFF; /* Default blue for not enabled */
    color: white;
  }
  
  .switch-display-button.enabled {
    background-color: #4CAF50; /* Green when enabled */
  }
  
  .switch-display-button.disabled {
    background-color: #e0e0e0; /* Grey when disabled */
    color: #a0a0a0;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/SwitchDisplay.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,yBAAyB,EAAE,iCAAiC;IAC5D,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,uBAAuB;EACpD;;EAEA;IACE,yBAAyB,EAAE,uBAAuB;IAClD,cAAc;IACd,mBAAmB;EACrB","sourcesContent":["/* SwitchDisplay.css*/\n\n.switch-display-button {\n    padding: 10px 20px;\n    margin: 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    background-color: #007BFF; /* Default blue for not enabled */\n    color: white;\n  }\n  \n  .switch-display-button.enabled {\n    background-color: #4CAF50; /* Green when enabled */\n  }\n  \n  .switch-display-button.disabled {\n    background-color: #e0e0e0; /* Grey when disabled */\n    color: #a0a0a0;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
